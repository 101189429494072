.game-control
  position: relative
  & .message
    text-align: center
    font-size: 5vw
  &-row
    flex-direction: row-reverse
    height: 100%
  &-col
    height: 100%
    text-align: center
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4)
    &.active
      background: rgba(#1890ff, 0.5) 0 0 no-repeat padding-box
    & .title
      font-size: 10vw
    & .winner
      position: absolute
      top: 0
      width: 100%
      background: red
      color: #fff
      text-align: center
      padding: 2vw 0
      margin: 0
      font-size: 6vw

      &:after, &:before
        top: 100%
        left: 50%
        border: solid transparent
        content: " "
        height: 0
        width: 0
        position: absolute
        pointer-events: none
      &:after
        border-color: rgba(136, 183, 213, 0)
        border-top-color: red
        border-width: 15px
        margin-left: -15px
      &:before
        border-color: rgba(194, 225, 245, 0)
        border-top-color: red
        border-width: 20px
        margin-left: -20px
      &.draw
        background: green
        &:after
          border-top-color: green
        &:before
          border-top-color: green
  & .custom-control
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    z-index: 3
    & .ticker
      display: flex
      flex-wrap: wrap
      align-items: center
      justify-content: center
      & .tickerArea
        text-align: center
        font-size: 5vw
        width: 100%

  & .control-buttons
    margin-top: 20px
    text-align: center
    &:first-child
      text-align: left
    &:last-child
      text-align: right
